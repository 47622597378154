<template>
  <div>
    <portal to="before-content" v-if="!breakpoints.greaterThanMd">
      <div class="filter-bar rfs-p-8-l">
        <font-awesome-icon icon="filter" />
        <button
          type="button"
          class="btn btn-text"
          :class="[{ active: smScreenControlsShow }]"
          @click="toggleControls()"
        >
          Open Comparison Settings <font-awesome-icon icon="angle-down" />
        </button>
      </div>
    </portal>
    <!-- Page Content -->
    <div class="page-buttons">
      <button
        data-add-compare-col
        v-show="columnsDisplaying < 4"
        type="button"
        class="btn btn-sm"
        :class="[{ active: false }]"
        @click="addColumn"
        :disabled="visitsAnyColumnLoading || patientsAnyColumnLoading"
      >
        <font-awesome-icon icon="plus-square" />
        Add a Comparison
      </button>
      <button
        v-if="breakpoints.greaterThanLg"
        type="button"
        class="btn btn-text"
        @click="fullCompareShowControls = !fullCompareShowControls"
      >
        <span v-if="fullCompareShowControls">Hide</span
        ><span v-else>Show</span> settings
      </button>
    </div>
    <div id="countTypeSwitcher" class="rfs-m-4-b">
      <button
        data-pop-builder-encounters-btn-test
        type="button"
        class="btn btn-primary rfs-m-2-r"
        :class="[
          {
            active: countTypeSelected === 'visits'
          }
        ]"
        @click="countTypeSelected = 'visits'"
      >
        <span>Encounters</span>
        <loader
          loaderStyle="bar"
          :zIndex="0"
          loaderWidth="25px"
          v-if="visitsAnyColumnLoading"
        />
      </button>
      <button
        data-pop-builder-patients-btn-test
        type="button"
        class="btn btn-primary"
        :class="[
          {
            active: countTypeSelected === 'patients'
          }
        ]"
        @click="countTypeSelected = 'patients'"
      >
        <span>Patients</span
        ><loader
          :zIndex="0"
          loaderWidth="25px"
          v-if="patientsAnyColumnLoading"
          loaderStyle="bar"
        />
      </button>
    </div>
    <div class="grid gap-12 compare-columns" :class="dynamicGridClasses">

      <!-- create compare population columns ( upto 4 Comparators - 1 baseline, + 3 ) -->

      <!-- Compare column top level -->
      <template v-for="i in columnsExisting">
        <div class="column-content" v-if="columnManager[i - 1]" :key="i">

          <!-- Compare column header -->
          <h3 v-if="i == 1" class="content-subtitle">Baseline</h3>
          <h3 v-else class="content-subtitle flex flex-wrap gap-4 items-center">
            Comparator
            <span style="text-transform: capitalize">{{
              converter.toWords(calcColumnNumber(i))
            }}</span>
            <button
              :data-test="'remove-col-' + i"
              v-if="i > 1 && columnsDisplaying > 2"
              type="button"
              class="icon-btn color-contrast"
              style="margin-left: auto"
              :disabled="visitsAnyColumnLoading || patientsAnyColumnLoading"
              @click="removeColumn(i)"
            >
            <font-awesome-icon
              v-tooltip="
                'Remove comparator ' +
                converter.toWords(calcColumnNumber(i)) +
                ' from comparison.'
              "
              icon="trash"
            />
            </button>
          </h3>
          <!-- end of Compare Column header -->

          <!-- chartRedrawKey: For charts to resize their component's key has to update anytime
          we add or remove columns so charts re-render to fit.
          The normal build-in responsive behavior doesn't apply here.
          That's why we are combo-ing in the iteration number with the column number. -->
          <compare-column
            data-compare-column
            :chartRedrawKey="i + columnsExisting"
            v-if="mountComplete"
            :populationStore="getNextPopulationStore(i)"
            :workflowStore="getNextWorkflowStore(i)"
            :columnIndex="i"
            :criteriaRedrawKey="globalKey"
            @local-selector-changed="localSelectorChanged"
            :dataType="countTypeSelected"
            @load-global-selector="renderGlobalSelector"
          ></compare-column>
        </div>
      </template>
    </div>
    <!-- End Page Content -->
    <portal to="after-content" v-if="loadGlobalSelector">
      <div
        id="comparisonDims"
        class="right-side-bar"
        :class="{
          'sm-screen-contols-show':
            smScreenControlsShow && !breakpoints.greaterThanMd,
          'sm-screen-contols': !breakpoints.greaterThanMd,
          'hidden-rbs': !fullCompareShowControls && breakpoints.greaterThanLg
        }"
      >
        <div
          class="rsb-content auto-scroll"
          :class="{ 'has-footer': !breakpoints.greaterThanMd }"
        >
          <section>
            <h3 class="color-secondary fw-700">Change Comparison Dimension</h3>
            <i class="italic fs-12">You must select at least one.</i>
            <checkbox-select
              :key="dimensionsSelectedKeyFormatted"
              v-model="dimensionsSelected"
              :options="dimensionsChoices"
              @input="updateDimsionsSelected"
            ></checkbox-select>
          </section>
          <section>
            <h3 class="color-secondary fw-700 rfs-m-8-t rfs-m-4-b">
              Global Reporting Criteria
            </h3>
            <global-selector
              data-compare-global-reporting-criteria
              :criteriaRedrawKey="localKey"
              :globalWorkflowStore="populationWorkflowStore"
              :globalPopulationStore="populationStore"
              :workflowStores="workflowStores"
              :populationStores="populationStores"
              :globalSelector="true"
              class="side-bar-controls"
              :disabled="visitsAnyColumnLoading || patientsAnyColumnLoading"
              v-if="mountComplete"
              @global-selector-changed="globalSelectorChanged"
            />
          </section>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
  import Chart from 'chart.js';
  import converter from 'number-to-words';

  const CheckboxSelect = () =>
    import('@/components/base/forms/checkbox_select.vue');
  const CompareColumn = () => import('@/components/popbuilder/CompareColumn');
  const GlobalSelector = () =>
    import('@/components/popbuilder/CompareGlobalSelector');
  export default {
    name: 'MultiDimCompare',
    props: {
      intCriteria: Array
    },
    inject: [
      'popBuilderCompareStore',
      'populationStore',
      'populationStore2',
      'populationStore3',
      'populationStore4',
      'populationWorkflowStore',
      'populationWorkflowStore2',
      'populationWorkflowStore3',
      'populationWorkflowStore4'
    ],
    components: {
      CheckboxSelect,
      CompareColumn,
      GlobalSelector
    },
    data() {
      return {
        globalKey: {}, // Set by the global selector, refreshes local selectors
        localKey: {}, // Set by the local selectors, refreshes global selector
        columnCountManager: [
          {
            colIndex: 1
          }
        ],
        columnManager: [true], // Which columns are on and off
        columnsDisplaying: 1, // The number of columns currently displaying
        columnsExisting: 1, // The number of columns that have been created
        converter: converter,
        smScreenControlsShow: false,
        fullCompareShowControls: true,
        dimensionsSelectedKey: 1,
        dimensionsSelected: [],
        disableControlsLoading: false, // replace with store loading stuff
        countTypeSelected: 'visits',
        dimensionsChoices: [
          { text: 'Population Characteristics', value: 'characteristics' },
          { text: 'Date', value: 'date_range' },
          { text: 'Data Segment (You/RWD)', value: 'data_segment' },
          { text: 'Patient Type', value: 'patient_status' },
          { text: 'Gender', value: 'gender' },
          { text: 'Age Range', value: 'age_range' }
        ],
        mountComplete: false, // data from store is not availible on mount of child, as it is mounted before the parent normally in vue. So, we force the child to mount after parent with this flag
        populationStores: [],
        workflowStores: [],
        storeIndexes: [],
        /**
         * In my analysis the random issue of contineous loading of comparator screen
         * is beacause of GlobalSelector component get loaded before loading of all CompareColumn
         * component. So the value change of 'Dirty' field of populationStore through GlobalSelector
         * is not get recognized(watched) from CompareColumn as they are not loaded yet.
         * So to hold GlobalSelector being render I introduced 'comparatorCount' & 'loadGlobalSelector'
        */
        comparatorCount: 0,
        loadGlobalSelector: false
      };
    },
    computed: {
      dynamicGridClasses() {
        if (this.breakpoints.greaterThanLg) {
          return `grid-cols-${this.columnsDisplaying}`;
        }
        return `grid-swipe-cols-${this.columnsDisplaying}`;
      },
      visitsAnyColumnLoading() {
        return (
          this.populationStore.visitsLoading() ||
          this.populationStore2.visitsLoading() ||
          this.populationStore3.visitsLoading() ||
          this.populationStore4.visitsLoading()
        );
      },
      patientsAnyColumnLoading() {
        return (
          this.populationStore.patientsLoading() ||
          this.populationStore2.patientsLoading() ||
          this.populationStore3.patientsLoading() ||
          this.populationStore4.patientsLoading()
        );
      },
      dimensionsSelectedKeyFormatted() {
        return this.dimensionsSelectedKey + '_dim_checkbox';
      }
    },
    methods: {
      calcColumnNumber(colIndex) {
        return this.columnCountManager.findIndex(
          (col) => col.colIndex === colIndex
        );
      },
      getNextPopulationStore(colIndex) {
        // First - if we have the population to use in the Compare store, just use that
        let storeColumn =
          this.popBuilderCompareStore.getPopulationAtColumn(colIndex);

        if (storeColumn && this.populationStores[storeColumn].store.isInUse()) {
          this.populationStores[storeColumn].inUse = true;
          return this.populationStores[storeColumn].store;
        }

        // We potentially have to look through this list twice:
        //    First, look for stores that are inuse but not set as inuse on the page
        //      (resetting the right stores when navigating back to the page)
        //    Second, look for stores that are not inuse

        for (let i in this.populationStores) {
          if (
            !this.populationStores[i].inUse &&
            this.populationStores[i].store.isInUse()
          ) {
            if (!this.popBuilderCompareStore.isPopulationUsedInAnyColumn(i)) {
              this.populationStores[i].inUse = true;
              this.popBuilderCompareStore.setColumnToPopulation(colIndex, i);
              return this.populationStores[i].store;
            }
          }
        }

        for (let i in this.populationStores) {
          if (!this.populationStores[i].inUse) {
            this.populationStores[i].inUse = true;
            this.populationStores[i].store.setInUse(true);

            // Store 0 is the global store, so it has to play the role of "base" that the other columns sync with, if needed
            if (i != 0 && !this.populationStores[i].store.populationIsSound()) {
              this.populationStores[i].store.syncWithAnotherStore(
                this.populationStores[0].store
              );
            }

            if (!this.popBuilderCompareStore.isPopulationUsedInAnyColumn(i)) {
              this.popBuilderCompareStore.setColumnToPopulation(colIndex, i);
              return this.populationStores[i].store;
            }
          }
        }
      },
      getNextWorkflowStore(colIndex) {
        // First - if we have the population to use in the Compare store, just use that
        let storeColumn =
          this.popBuilderCompareStore.getPopulationAtColumn(colIndex);

        if (storeColumn && this.workflowStores[storeColumn].store.isInUse()) {
          this.workflowStores[storeColumn].inUse = true;
          return this.workflowStores[storeColumn].store;
        }

        // We potentially have to look through this list twice:
        //    First, look for stores that are inuse but not set as inuse on the page
        //      (resetting the right stores when navigating back to the page)
        //    Second, look for stores that are not inuse

        for (let i in this.workflowStores) {
          if (
            !this.workflowStores[i].inUse &&
            this.workflowStores[i].store.isInUse()
          ) {
            this.workflowStores[i].inUse = true;

            if (i != 0) {
              this.workflowStores[i].store.syncWithAnotherStore(
                this.workflowStores[0].store,
                'global'
              );
            }

            return this.workflowStores[i].store;
          }
        }

        for (let i in this.workflowStores) {
          if (!this.workflowStores[i].inUse) {
            this.workflowStores[i].inUse = true;
            this.workflowStores[i].store.setInUse(true);

            this.workflowStores[i].store.resetPopulationCriteriaLocal();

            // Store 0 is the global store, so it has to play the role of "base" that the other columns sync with, if needed
            if (i != 0) {
              this.workflowStores[i].store.syncWithAnotherStore(
                this.workflowStores[0].store,
                'force'
              );
            }

            return this.workflowStores[i].store;
          }
        }
      },
      async init() {
        this.popBuilderCompareStore.cleanColumnPopulationMap();

        // Get our stores in order
        this.populationStores = [
          { store: this.populationStore, inUse: false },
          { store: this.populationStore2, inUse: false },
          { store: this.populationStore3, inUse: false },
          { store: this.populationStore4, inUse: false }
        ];

        this.workflowStores = [
          { store: this.populationWorkflowStore, inUse: false },
          { store: this.populationWorkflowStore2, inUse: false },
          { store: this.populationWorkflowStore3, inUse: false },
          { store: this.populationWorkflowStore4, inUse: false }
        ];

        // Link up the population and workflow stores
        this.populationStore2.linkToWorkflowStore(
          this.populationWorkflowStore2
        );
        this.populationStore3.linkToWorkflowStore(
          this.populationWorkflowStore3
        );
        this.populationStore4.linkToWorkflowStore(
          this.populationWorkflowStore4
        );

        this.populationWorkflowStore2.linkToPopulationStore(
          this.populationStore2
        );
        this.populationWorkflowStore3.linkToPopulationStore(
          this.populationStore3
        );
        this.populationWorkflowStore4.linkToPopulationStore(
          this.populationStore4
        );

        // We have to allow duplicate requests here so that if you have two populations with
        //    the same characteristics saved, things don't die
        this.populationStore.setDuplicateRequestAllowed(true);
        this.populationStore2.setDuplicateRequestAllowed(true);
        this.populationStore3.setDuplicateRequestAllowed(true);
        this.populationStore4.setDuplicateRequestAllowed(true);

        // Load the default criteria to the baseline store
        this.populationStore.LoadDefaultCriteria();
        this.populationStore.loadDefaultFilters();

        // Get the dimensions from the base store
        this.dimensionsSelected =
          this.populationWorkflowStore.getComparisonDimensions();
        this.dimensionsSelectedKey++;

        // data from store is not availible on mount of child, as it is mounted before the parent normally in vue. So, we force the child to mount after parent with this flag
        this.mountComplete = true;

        // Column 1 is always showing, but let's see how many columns we need to add
        for (let i = 1; i < this.populationStores.length; i++) {
          if (this.populationStores[i].store.isInUse()) {
            this.addColumn();
          }
        }

        // If we never found a second column, we need to turn it on manually
        if (this.columnsDisplaying == 1) {
          this.addColumn();
        }
      },
      addColumn() {
        this.columnManager[this.columnsExisting] = true;
        this.columnsDisplaying++;
        this.columnsExisting++;

        this.columnCountManager.push({
          colIndex: this.columnsExisting
        });

        // The stores are set in parameters sent to the column component
      },
      removeColumn(col) {
        this.columnManager[col - 1] = false;

        const newCounts = this.columnCountManager.filter(function (obj) {
          return obj.colIndex !== col;
        });
        this.columnCountManager = newCounts;

        let columnPopulationMap =
          this.popBuilderCompareStore.getColumnPopulationMap();

        for (let i in columnPopulationMap) {
          if (i == col) {
            let storeIndex = columnPopulationMap[i];

            // Should be able to assume this
            // tests
            this.populationStores[storeIndex].inUse = false;
            this.populationStores[storeIndex].store.setInUse(false);
            this.populationStores[storeIndex].store.reset();
            this.workflowStores[storeIndex].inUse = false;
            this.workflowStores[storeIndex].store.setInUse(false);
            this.workflowStores[
              storeIndex
            ].store.resetPopulationCriteriaLocal();

            this.popBuilderCompareStore.clearColumnPopulation(i);
            break;
          }
        }

        this.columnsDisplaying--;
      },
      // When a dimension is made global or made local, things might need to happen in the columns
      updateDimsionsSelected(val) {
        // Difference
        let currentSetup =
          this.workflowStores[0].store.getComparisonDimensions();
        let localToGlobal = currentSetup.filter((x) => !val.includes(x));

        for (let i in this.workflowStores) {
          this.workflowStores[i].store.setComparisonDimensions(val);

          if (localToGlobal.length > 0) {
            for (let dim in localToGlobal) {
              if (localToGlobal[dim] == 'characteristics') {
                if (this.workflowStores[i].store.isInUse()) {
                  let chara =
                    this.populationStores[0].store.getCurrentPopulationSelection();

                  this.populationStores[i].store.setCurrentPopulationSelection(
                    chara
                  );
                  this.populationStores[
                    i
                  ].store.loadSavedPopulationByFilterData(chara);
                  this.populationStores[i].store.setDirty(true);
                }
              }
              if (localToGlobal[dim] == 'date_range') {
                this.workflowStores[i].store.setDateRange(
                  this.workflowStores[0].store.getDateRange()
                );

                if (this.populationStores[i].store.isInUse()) {
                  this.populationStores[i].store.setDirty(true);
                }
              }
              if (localToGlobal[dim] == 'dataSegment') {
                this.workflowStores[i].store.setDataSegment(
                  this.workflowStores[0].store.getDataSegment()
                );

                if (this.workflowStores[i].store.isInUse()) {
                  this.populationStores[i].store.setDirty(true);
                }
              }
              if (localToGlobal[dim] == 'patient_status') {
                let patientStatus =
                  this.workflowStores[0].store.getPatientStatus();
                this.workflowStores[i].store.setPatientStatus(patientStatus);
              }
              if (localToGlobal[dim] == 'gender') {
                this.workflowStores[i].store.setGender(
                  this.workflowStores[0].store.getGender()
                );
              }
              if (localToGlobal[dim] == 'age_range') {
                this.workflowStores[i].store.setAgeRange(
                  this.workflowStores[0].store.getAgeRange()
                );
              }

              this.globalSelectorChanged(localToGlobal[dim]);
            }
          }
        }
      },
      toggleControls() {
        this.smScreenControlsShow = !this.smScreenControlsShow;
      },
      globalSelectorChanged(type) {
        if (!this.globalKey[type]) {
          this.$set(this.globalKey, type, 1);
        } else {
          this.globalKey[type]++;
        }
      },
      localSelectorChanged(type) {
        if (!this.localKey[type]) {
          this.$set(this.localKey, type, 1);
        } else {
          this.localKey[type]++;
        }
      },
      renderGlobalSelector() {
        this.comparatorCount++;
        if (this.comparatorCount === this.columnsDisplaying) {
          this.loadGlobalSelector = true;
        }
      }
    },
    beforeMount() {
      /**
       * This is require to sync back all previously selected comparators with baseline store,
       * when we navigate between Compare Population & Build Population
      */
      if (this.populationStore2.isInUse()) {
        this.loadGlobalSelector = true;
        this.populationWorkflowStore2.syncWithAnotherStore(this.populationWorkflowStore, 'force');
      }
      if (this.populationStore3.isInUse()) {
        this.populationWorkflowStore3.syncWithAnotherStore(this.populationWorkflowStore, 'force');
      }
      if (this.populationStore4.isInUse()) {
        this.populationWorkflowStore4.syncWithAnotherStore(this.populationWorkflowStore, 'force');
      }
    },
    mounted() {
      this.init();

      // resize charts when the main container changes size (when sidebar hiden)
      const resizeAllCharts = () => {
        // eslint-disable-next-line no-undef
        for (var id in Chart.instances) {
          // eslint-disable-next-line no-undef
          Chart.instances[id].resize();
        }
      };

      const appContentEl = document.getElementById('appContent');

      new ResizeObserver(resizeAllCharts).observe(appContentEl);
      // END: resize charts when the main container changes size (when sidebar hiden)
    },
    beforeDestroy() {
      /** As 'population.js' store is common for Build Population and 'Baseline' of Compare Population.
       * So when we navigate back to Build Population from Compare Population, the data get displayed
       * of 'Baseline' of Compare Population but the hospitals are remain selected for global values.
       * Therefor this is required to restore data in Build Population based on global hospital values.
       * */
      let selectedHospitalList = this.$store.getters['auth/getSelectedHospitalList'];
      if (selectedHospitalList !== undefined && selectedHospitalList !== null && selectedHospitalList.length > 0) {
        let columnPopulationMap = this.popBuilderCompareStore.getColumnPopulationMap();

        for (let i in columnPopulationMap) {
          let storeIndex = (+(columnPopulationMap[i]));
          if (storeIndex === 0) {
            const currentDS_mode = this.workflowStores[storeIndex].store.getDataSegment().mode;
            if (currentDS_mode === 'hospital') {
              this.workflowStores[storeIndex].store.setDataSegment({mode: 'hospital', value: selectedHospitalList});
              break;
            }
          }
        }
      }
    }
  };
</script>

<style lang="scss">
  @use '@/assets/scss/responsive';
  @use './node_modules/include-media/dist/include-media.scss' as im with
    ($breakpoints: responsive.$breakpoints);
  @use '@/assets/scss/vars.scss' as v;
  @use '@/assets/scss/margin_padding';
  .compare-columns {
    scroll-snap-type: x mandatory;
    overflow-x: auto;
    clear: both;
    min-height: calc(100vh - 245px);
  }
  .column-content {
    scroll-snap-align: start;
    clear: both;
  }

  .page-buttons {
    float: right;
    @extend .rfs-m-4-b;
  }

  .gender-profile-chart,
  .status-profile-chart {
    canvas {
      height: 175px !important;
    }
  }

  .age-by-gender-profile-chart {
    canvas {
      height: 250px !important;
    }
  }

  .hidden-rbs {
    position: fixed;
    right: 0;
    margin-right: -400px;
    transition: margin-right 1s;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.75);
    z-index: 1;
  }

  @include im.media('>=lg') {
    .page-buttons {
      margin-top: -(v.$headerHeight);
    }
  }
</style>
