var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.breakpoints.greaterThanMd)?_c('portal',{attrs:{"to":"before-content"}},[_c('div',{staticClass:"filter-bar rfs-p-8-l"},[_c('font-awesome-icon',{attrs:{"icon":"filter"}}),_c('button',{staticClass:"btn btn-text",class:[{ active: _vm.smScreenControlsShow }],attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleControls()}}},[_vm._v(" Open Comparison Settings "),_c('font-awesome-icon',{attrs:{"icon":"angle-down"}})],1)],1)]):_vm._e(),_c('div',{staticClass:"page-buttons"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.columnsDisplaying < 4),expression:"columnsDisplaying < 4"}],staticClass:"btn btn-sm",class:[{ active: false }],attrs:{"data-add-compare-col":"","type":"button","disabled":_vm.visitsAnyColumnLoading || _vm.patientsAnyColumnLoading},on:{"click":_vm.addColumn}},[_c('font-awesome-icon',{attrs:{"icon":"plus-square"}}),_vm._v(" Add a Comparison ")],1),(_vm.breakpoints.greaterThanLg)?_c('button',{staticClass:"btn btn-text",attrs:{"type":"button"},on:{"click":function($event){_vm.fullCompareShowControls = !_vm.fullCompareShowControls}}},[(_vm.fullCompareShowControls)?_c('span',[_vm._v("Hide")]):_c('span',[_vm._v("Show")]),_vm._v(" settings ")]):_vm._e()]),_c('div',{staticClass:"rfs-m-4-b",attrs:{"id":"countTypeSwitcher"}},[_c('button',{staticClass:"btn btn-primary rfs-m-2-r",class:[
        {
          active: _vm.countTypeSelected === 'visits'
        }
      ],attrs:{"data-pop-builder-encounters-btn-test":"","type":"button"},on:{"click":function($event){_vm.countTypeSelected = 'visits'}}},[_c('span',[_vm._v("Encounters")]),(_vm.visitsAnyColumnLoading)?_c('loader',{attrs:{"loaderStyle":"bar","zIndex":0,"loaderWidth":"25px"}}):_vm._e()],1),_c('button',{staticClass:"btn btn-primary",class:[
        {
          active: _vm.countTypeSelected === 'patients'
        }
      ],attrs:{"data-pop-builder-patients-btn-test":"","type":"button"},on:{"click":function($event){_vm.countTypeSelected = 'patients'}}},[_c('span',[_vm._v("Patients")]),(_vm.patientsAnyColumnLoading)?_c('loader',{attrs:{"zIndex":0,"loaderWidth":"25px","loaderStyle":"bar"}}):_vm._e()],1)]),_c('div',{staticClass:"grid gap-12 compare-columns",class:_vm.dynamicGridClasses},[_vm._l((_vm.columnsExisting),function(i){return [(_vm.columnManager[i - 1])?_c('div',{key:i,staticClass:"column-content"},[(i == 1)?_c('h3',{staticClass:"content-subtitle"},[_vm._v("Baseline")]):_c('h3',{staticClass:"content-subtitle flex flex-wrap gap-4 items-center"},[_vm._v(" Comparator "),_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(_vm.converter.toWords(_vm.calcColumnNumber(i))))]),(i > 1 && _vm.columnsDisplaying > 2)?_c('button',{staticClass:"icon-btn color-contrast",staticStyle:{"margin-left":"auto"},attrs:{"data-test":'remove-col-' + i,"type":"button","disabled":_vm.visitsAnyColumnLoading || _vm.patientsAnyColumnLoading},on:{"click":function($event){return _vm.removeColumn(i)}}},[_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
              'Remove comparator ' +
              _vm.converter.toWords(_vm.calcColumnNumber(i)) +
              ' from comparison.'
            ),expression:"\n              'Remove comparator ' +\n              converter.toWords(calcColumnNumber(i)) +\n              ' from comparison.'\n            "}],attrs:{"icon":"trash"}})],1):_vm._e()]),(_vm.mountComplete)?_c('compare-column',{attrs:{"data-compare-column":"","chartRedrawKey":i + _vm.columnsExisting,"populationStore":_vm.getNextPopulationStore(i),"workflowStore":_vm.getNextWorkflowStore(i),"columnIndex":i,"criteriaRedrawKey":_vm.globalKey,"dataType":_vm.countTypeSelected},on:{"local-selector-changed":_vm.localSelectorChanged,"load-global-selector":_vm.renderGlobalSelector}}):_vm._e()],1):_vm._e()]})],2),(_vm.loadGlobalSelector)?_c('portal',{attrs:{"to":"after-content"}},[_c('div',{staticClass:"right-side-bar",class:{
        'sm-screen-contols-show':
          _vm.smScreenControlsShow && !_vm.breakpoints.greaterThanMd,
        'sm-screen-contols': !_vm.breakpoints.greaterThanMd,
        'hidden-rbs': !_vm.fullCompareShowControls && _vm.breakpoints.greaterThanLg
      },attrs:{"id":"comparisonDims"}},[_c('div',{staticClass:"rsb-content auto-scroll",class:{ 'has-footer': !_vm.breakpoints.greaterThanMd }},[_c('section',[_c('h3',{staticClass:"color-secondary fw-700"},[_vm._v("Change Comparison Dimension")]),_c('i',{staticClass:"italic fs-12"},[_vm._v("You must select at least one.")]),_c('checkbox-select',{key:_vm.dimensionsSelectedKeyFormatted,attrs:{"options":_vm.dimensionsChoices},on:{"input":_vm.updateDimsionsSelected},model:{value:(_vm.dimensionsSelected),callback:function ($$v) {_vm.dimensionsSelected=$$v},expression:"dimensionsSelected"}})],1),_c('section',[_c('h3',{staticClass:"color-secondary fw-700 rfs-m-8-t rfs-m-4-b"},[_vm._v(" Global Reporting Criteria ")]),(_vm.mountComplete)?_c('global-selector',{staticClass:"side-bar-controls",attrs:{"data-compare-global-reporting-criteria":"","criteriaRedrawKey":_vm.localKey,"globalWorkflowStore":_vm.populationWorkflowStore,"globalPopulationStore":_vm.populationStore,"workflowStores":_vm.workflowStores,"populationStores":_vm.populationStores,"globalSelector":true,"disabled":_vm.visitsAnyColumnLoading || _vm.patientsAnyColumnLoading},on:{"global-selector-changed":_vm.globalSelectorChanged}}):_vm._e()],1)])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }